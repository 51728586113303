<template>
  <div class="notification-dialog notifications-list">
    <div class="d-flex">
      <h5 class="h5-text mb-2">Уведомления</h5>
      <v-spacer></v-spacer>
      <v-btn class="set-all-viewed-btn" text @click="setAllViewed()">Отметить все прочитанными</v-btn>
    </div>
      <v-list>
        <v-list-item v-for="item in notifications" :key="item.id" class="mb-3" :class="{'not-viewed': !item.viewed}">
          <v-list-item-avatar>
            <v-btn v-if="!item.viewed" color="primary" @click="switchViewed(item)" icon>
              <v-icon>mdi-circle-medium</v-icon>
            </v-btn>
            <v-btn v-if="item.viewed" @click="switchViewed(item)" icon>
              <v-icon>mdi-circle-medium</v-icon>
            </v-btn>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>{{formatDate(item.created_at)}}</v-list-item-subtitle>
            <v-list-item-title>{{getText(item)}}</v-list-item-title>
            <div class="request-id">Запрос №{{item.request_id}}</div>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn color="primary" outlined :to="requestLink(item)">Подробнее</v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    <div>
      <v-pagination class="d-inline-block" v-model="currentPage" :length="pageCount" total-visible="10"
                    @input="navigate"></v-pagination>
    </div>
    </div>
</template>

<script>
import { Http } from '../../apis/Http'
// import moment from 'moment'
import NotificationCommon from '../components/NotificationCommon'

export default {
  data() {
    return {
      notifications: [],
      currentPage: 1,
      pageCount: 1,
      itemsPerPage: 20,
      prevPage: 1
    }
  },

  created() {
    this.$setLoading(true)
    this.getNotifications()
    this.$socket.on('notification', () => {
      if (this.$route.name === 'Notifications') {
        this.$setLoading(true)
        this.getNotifications()
      }
    })
  },

  watch: {
    '$store.state.hasNotifications': function(val, oldVal) {
      if (val !== oldVal) this.getNotifications()
    }
  },

  methods: {

    navigate() {
      if (this.currentPage !== this.prevPage) {
        this.$setLoading(true)
        this.getNotifications()
      }
    },

    getNotifications() {
      Http.get('/notifications/get-not-viewed',
        { params: { all: true, page: this.currentPage, limit: this.itemsPerPage } })
        .then(res => {
          if (!res?.data) return
          this.notifications = res.data.data
          this.pageCount = Math.ceil(res.data.total / this.itemsPerPage)
          this.prevPage = parseInt(this.currentPage)
        }).finally(() => {
          this.$setLoading(false)
        })
    },
    requestLink(item) {
      if (!item.request_id) return
      if (this.$store.state.auth.userRole === 'manager-ds') {
        return '/request-leas-objects/' + item.request_id
      } else {
        return '/search-leas-objects/' + item.request_id
      }
    },

    ...NotificationCommon
  }
}
</script>
